import { dehydrate, QueryClient, useQueryClient } from '@tanstack/react-query'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { createMagentoClient } from 'graphql/magentoClient'
import type { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE, PAGE_SIZE } from '~/config/constants'
import {
  AccordionDocument,
  BlackfridayNewsletterModuleDocument,
  BlogOverviewModuleDocument,
  EditorialGalleryDocument,
  ImageModuleDocument,
  InstagramModuleDocument,
  LocalizedSlugsQuery,
  LogoGridDocument,
  LookbookDocument,
  NavigationQuery,
  PageContentModulesCollection,
  PreContentQuery,
  ProductsSliderDocument,
  ProductTeaserDocument,
  QuoteDocument,
  ShopTheLookDocument,
  StageDocument,
  TabModuleDocument,
  TeaserModuleDocument,
  TextImageTeaserDocument,
  TextTeaserDocument,
  SeoTextModuleDocument,
  useLocalizedSlugsQuery,
  useNavigationQuery,
  useHeaderQuery,
  usePreContentQuery,
  useStageQuery,
  VideoModuleDocument,
  HeaderQuery,
  HiddenH1Document,
  MobileHeaderQuery,
  useMobileHeaderQuery,
  HeroStageModuleDocument,
  NewsletterDocument,
} from '@contentfulTypes'
import {
  CategoriesQuery,
  CategoryInterface,
  CategoryUrlsQuery,
  ConfigurableProduct,
  CustomAttributeMetadataQuery,
  PdpQuery,
  ProductsQuery,
  ProductUrlsQuery,
  RouteQuery,
  useCategoriesQuery,
  useCategoryUrlsQuery,
  useCustomAttributeMetadataQuery,
  usePdpQuery,
  useProductsQuery,
  useProductUrlsQuery,
  useRouteQuery,
} from '@magentoTypes'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import dynamic from 'next/dynamic'
import invariant from 'tiny-invariant'
import { PREVIEW_MODE_KEY } from '~/hooks/usePreviewMode'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { config } from '~/lib/queryClientInit'
import Layout from '~/modules/Layout/Layout'
import { parseFilterString } from '~/modules/PLP/lib/parseFilterString'
import { parseSortString } from '~/modules/PLP/lib/parseSortString'
import { transformFilterToMagentoFilterObject } from '~/modules/PLP/lib/transformFilterToMagentoFilterObject'
import { NextPageWithLayout } from './_app'
import { getStoreCode } from '~/lib/getStoreCode'
import { parseSpecialOfferCategory } from '~/modules/PLP/lib/parseSpecialOfferCategory'
import redirects from '~/redirects/cms.json'
import blackweek from '~/redirects/blackweek.json'
import { fetchStructuredData } from '~/lib/fetchStructuredData'
import { VariationsStrategy } from '~/modules/SearchResultPageCio/lib/constants'

//! Add content modules here
export const contentQueryDocs = {
  ImageModule: ImageModuleDocument,
  TextTeaser: TextTeaserDocument,
  SeoTextModule: SeoTextModuleDocument,
  TextImageTeaser: TextImageTeaserDocument,
  Stage: StageDocument,
  Quote: QuoteDocument,
  LogoGrid: LogoGridDocument,
  TeaserModule: TeaserModuleDocument,
  Accordion: AccordionDocument,
  Video: VideoModuleDocument,
  EditorialGallery: EditorialGalleryDocument,
  ShopTheLook: ShopTheLookDocument,
  ProductsSlider: ProductsSliderDocument,
  ProductTeaser: ProductTeaserDocument,
  BlogOverviewModule: BlogOverviewModuleDocument,
  TabModule: TabModuleDocument,
  InstagramModule: InstagramModuleDocument,
  Lookbook: LookbookDocument,
  BlackfridayNewsletterModule: BlackfridayNewsletterModuleDocument,
  HiddenH1: HiddenH1Document,
  HeroStageModule: HeroStageModuleDocument,
  Newsletter: NewsletterDocument,
}

const PDP = dynamic(() => import('../modules/PDP/PDP'))
const PLP = dynamic(() => import('../modules/PLP/PLP'))
const CmsPage = dynamic(() => import('../modules/CmsPage/CmsPage'))
const CioCategoryPage = dynamic(() => import('../modules/SearchResultPageCio/CioCategoryPage'))
const CioCmsPage = dynamic(() => import('../modules/SearchResultPageCio/CioCmsPage'))

const Page: NextPageWithLayout<InferGetStaticPropsType<typeof getStaticProps>> = ({
  structuredData,
  cioCollectionId,
  cioGroupId,
  cioShowVariationsStrategy,
}) => {
  const router = useRouter()

  const queryClient = useQueryClient()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ queryClient, locale: finalLocale })
  const slug = router.query['slug'] as string[]
  const stringSlug = Array.isArray(slug) ? slug.join('/') : slug
  const { data, isFetched, isLoading } = useRouteQuery(
    magentoClient,
    {
      slug: stringSlug?.replace(/^-/, ''),
    },
    { enabled: !!stringSlug },
  )

  if (!isFetched && isLoading) return null

  if (data?.route?.type === 'PRODUCT') {
    invariant(
      data?.route?.__typename === 'ConfigurableProduct',
      'Wrong __typename for ConfigurableProduct Page',
    )
    return <PDP urlKey={data?.route?.url_key as string} structuredData={structuredData} />
  }

  if (data?.route?.type === 'CATEGORY' && !cioCollectionId && !cioGroupId) {
    return <PLP slug={stringSlug} />
  }

  if (data?.route?.type === 'CATEGORY' && (!!cioCollectionId || !!cioGroupId)) {
    return (
      <CioCategoryPage
        slug={stringSlug}
        filterName={cioCollectionId ? 'collection_id' : 'group_id'}
        filterValue={(cioCollectionId || cioGroupId) as string}
        showVariationsStrategy={(cioShowVariationsStrategy as VariationsStrategy) || undefined}
      />
    )
  }

  if (!!cioCollectionId || !!cioGroupId) {
    return (
      <CioCmsPage
        slug={stringSlug}
        filterName={cioCollectionId ? 'collection_id' : 'group_id'}
        filterValue={(cioCollectionId || cioGroupId) as string}
        showVariationsStrategy={(cioShowVariationsStrategy as VariationsStrategy) || undefined}
      />
    )
  }

  return <CmsPage slug={stringSlug} />
}

Page.getLayout = (page) => <Layout>{page}</Layout>

export const getStaticProps: GetStaticProps = async ({
  locale = DEFAULT_LOCALE,
  params,
  preview = false,
}) => {
  const finalLocale = createLanguageRegionLocale(locale)
  const queryClient = new QueryClient(config)
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })
  const magentoClientNoCache = createMagentoClient({
    usePost: true,
    queryClient,
    locale: finalLocale,
  })
  const contentfulClient = createGraphQLClient({ preview })
  const slug = (params?.slug as string[])?.join('/')
  let fetchedStructuredData = null

  let cioCollectionId: string | null = null
  let cioGroupId: string | null = null
  let cioShowVariationsStrategy: string | null = null

  if (slug) {
    /*** ----- START: Temp for blackweek redirects ----- ***
     *** This might look ugly, but it's due to https://stackoverflow.com/questions/77015132/nextjs-redirects-not-applied-to-client-side-routing
     ***/
    const blackweekRedirect = blackweek?.redirects?.find(
      (redirect) =>
        redirect?.store === process.env.NEXT_PUBLIC_STORE_CODE && redirect?.source === `/${slug}`,
    )
    if (blackweekRedirect) {
      return {
        redirect: {
          statusCode: 302,
          destination: ['de-CH', 'fr-CH', 'it-CH'].includes(finalLocale)
            ? `/${locale}${blackweekRedirect.destination}`
            : blackweekRedirect.destination,
        },
      }
    }
    /*** ----- END: Temp for blackweek redirects ----- ***/

    /*** ----- START: prefetching navigation ----- ***/
    await queryClient.prefetchQuery<NavigationQuery>(
      useNavigationQuery.getKey({ preview, locale: finalLocale }),
      async () =>
        await contentfulClient.request(useNavigationQuery.document, {
          preview,
          locale: finalLocale,
        }),
    )
    /*** ----- END: prefetching navigation ----- ***/

    /*** ----- START: prefetching navigation contents ----- ***/
    try {
      await queryClient.fetchQuery<HeaderQuery>(
        useHeaderQuery.getKey({ preview, locale: finalLocale }),
        async () =>
          await contentfulClient.request(useHeaderQuery.document, {
            preview,
            locale: finalLocale,
          }),
      )

      await queryClient.fetchQuery<MobileHeaderQuery>(
        useMobileHeaderQuery.getKey({ preview, locale: finalLocale }),
        async () =>
          await contentfulClient.request(useMobileHeaderQuery.document, {
            preview,
            locale: finalLocale,
          }),
      )
    } catch (error) {
      console.log(error)
    }
    /*** ----- END: prefetching navigation contents ----- ***/

    /*** ----- START: prefetching dynamic contents ----- ***/
    try {
      const storeDomain = `https://www.${process.env.NEXT_PUBLIC_DOMAIN_NAME}/${
        ['de-CH', 'fr-CH', 'it-CH'].includes(finalLocale) ? `${locale}/` : ''
      }`

      const prefetchContent = async (pageSlug: string, prefix?: string) => {
        let isThisPageExist = false
        const page = prefix ? `${prefix}-${pageSlug}` : pageSlug

        try {
          const preFetchContentPage = await queryClient.fetchQuery<PreContentQuery>(
            usePreContentQuery.getKey({ slug: page, preview, locale: finalLocale }),
            async () =>
              await contentfulClient.request(usePreContentQuery.document, {
                slug: page,
                preview,
                locale: finalLocale,
              }),
          )

          isThisPageExist = (preFetchContentPage?.pageCollection?.items?.length || 0) > 0

          if (isThisPageExist) {
            const fetchContent = async (page: PageContentModulesCollection['items'][0]) => {
              if (!contentQueryDocs?.[page?.__typename as keyof typeof contentQueryDocs]) return
              await queryClient.prefetchQuery(
                [page?.__typename, { id: page?.sys?.id, preview, locale: finalLocale }],
                async () =>
                  await contentfulClient.request(
                    contentQueryDocs?.[page?.__typename as keyof typeof contentQueryDocs],

                    {
                      id: page?.sys?.id,
                      preview,
                      locale: finalLocale,
                    },
                  ),
              )
            }
            const contentItems =
              preFetchContentPage?.pageCollection?.items?.[0]?.contentModulesCollection?.items

            if (contentItems) {
              await Promise.all(
                contentItems.map(async (page) => {
                  await fetchContent(page as PageContentModulesCollection['items'][0])
                }),
              )
            }

            if (preFetchContentPage?.pageCollection?.items?.[0]?.stage) {
              await queryClient.prefetchQuery(
                useStageQuery.getKey({
                  id: preFetchContentPage?.pageCollection?.items?.[0]?.stage.sys?.id,
                  preview,
                  locale: finalLocale,
                }),
                async () =>
                  await contentfulClient.request(StageDocument, {
                    id: preFetchContentPage?.pageCollection?.items?.[0]?.stage?.sys?.id,
                    preview,
                    locale: finalLocale,
                  }),
              )
            }

            if (!prefix && process.env.NEXT_PUBLIC_CONSTRUCTORIO_API_KEYS) {
              cioCollectionId =
                preFetchContentPage?.pageCollection?.items?.[0]?.cioCollectionId ?? null
              cioGroupId = preFetchContentPage?.pageCollection?.items?.[0]?.cioGroupId ?? null
              cioShowVariationsStrategy =
                preFetchContentPage?.pageCollection?.items?.[0]?.cioShowVariationsStrategy ?? null
            }
          }
        } catch (error) {
          console.log(error)
        }

        return isThisPageExist
      }

      const prefetchLocalizedSlugs = async (page: string) => {
        try {
          const localizedSlugs = await queryClient.fetchQuery(
            useLocalizedSlugsQuery.getKey({ slug: page, locale: finalLocale }),
            async () =>
              await contentfulClient.request<LocalizedSlugsQuery>(useLocalizedSlugsQuery.document, {
                slug: page,
                locale: finalLocale,
              }),
          )
          const relatedPageSlug = localizedSlugs.pageCollection?.slugs?.[0]?.relatedPage
            ?.slug as string
          if (relatedPageSlug) {
            await queryClient.prefetchQuery(
              useLocalizedSlugsQuery.getKey({ slug: relatedPageSlug, locale: finalLocale }),
              async () =>
                await contentfulClient.request<LocalizedSlugsQuery>(
                  useLocalizedSlugsQuery.document,
                  { slug: relatedPageSlug, locale: finalLocale },
                ),
            )
          }
        } catch (error) {
          console.log(error)
        }
      }

      let routeData = null as any
      try {
        routeData = await queryClient.fetchQuery(
          useRouteQuery.getKey({ slug: slug?.replace(/^-/, '') }),
          async () =>
            await magentoClient.request<RouteQuery>(useRouteQuery.document, {
              slug: slug?.replace(/^-/, ''),
            }),
        )
      } catch (err) {
        // Do nothing...
      }

      const prefetchCurrentPage = await prefetchContent(slug)

      switch (routeData?.route?.type) {
        case 'PRODUCT':
          invariant(
            routeData?.route?.__typename === 'ConfigurableProduct',
            'Wrong __typename for ConfigurableProduct Page',
          )
          await queryClient.prefetchQuery(
            useCustomAttributeMetadataQuery.getKey(),
            async () =>
              await magentoClient.request<CustomAttributeMetadataQuery>(
                useCustomAttributeMetadataQuery.document,
              ),
          )
          try {
            const productData = await queryClient.fetchQuery(
              usePdpQuery.getKey({
                urlKey: routeData?.route?.url_key as string,
              }),
              async () =>
                await magentoClient.request<PdpQuery>(usePdpQuery.document, {
                  urlKey: (routeData?.route as ConfigurableProduct)?.url_key as string,
                }),
            )

            if (productData?.products?.items?.[0]?.id) {
              fetchedStructuredData = await fetchStructuredData(
                finalLocale,
                productData?.products?.items?.[0]?.sku as string,
              )

              const productUrls = await queryClient.fetchQuery(
                useProductUrlsQuery.getKey({
                  identifier: productData?.products?.items?.[0]?.id as number,
                }),
                async () =>
                  await magentoClient.request<ProductUrlsQuery>(useProductUrlsQuery.document, {
                    identifier: productData?.products?.items?.[0]?.id,
                  }),
              )
              // Check for the possible product redirects
              const productUrl = productUrls?.productUrls?.find((v) => {
                let correctedDomain: string = v as string
                if (v?.startsWith('https://old.benuta.eu/'))
                  correctedDomain = 'https://www.benuta.eu/'
                return correctedDomain?.startsWith(storeDomain)
              })
              const expectedProductSlug = productUrl
                ?.replace('https://old.benuta.eu/', 'https://www.benuta.eu/')
                ?.replace(storeDomain, '')
              if (productUrl && expectedProductSlug !== slug) {
                return {
                  redirect: {
                    statusCode: 301,
                    destination: `/${expectedProductSlug}`,
                  },
                }
              }
            }

            if (productData?.products?.items?.length === 0) {
              return { notFound: true }
            }
          } catch (error) {
            console.log(error)
          }
          break
        case 'CATEGORY':
          if ((routeData?.route as unknown as CategoryInterface)?.id) {
            const categoryUrls = await queryClient.fetchQuery(
              useCategoryUrlsQuery.getKey({
                identifier: (routeData?.route as unknown as CategoryInterface)?.id as number,
              }),
              async () =>
                await magentoClient.request<CategoryUrlsQuery>(useCategoryUrlsQuery.document, {
                  identifier: (routeData?.route as unknown as CategoryInterface)?.id,
                }),
            )
            // Check for the possible category redirects
            const categoryUrl = categoryUrls?.categoryUrls?.find((v) => {
              let correctedDomain: string = v as string
              if (v?.startsWith('https://old.benuta.eu/'))
                correctedDomain = 'https://www.benuta.eu/'
              return correctedDomain?.startsWith(storeDomain)
            })
            const expectedCategorySlug = categoryUrl
              ?.replace('https://old.benuta.eu/', 'https://www.benuta.eu/')
              ?.replace(storeDomain, '')
            if (categoryUrl && expectedCategorySlug !== slug) {
              return {
                redirect: {
                  statusCode: 301,
                  destination: `/${expectedCategorySlug}`,
                },
              }
            }
          }

          await prefetchContent(slug, 'header')

          await queryClient.prefetchQuery(
            useCategoriesQuery.getKey({
              uid: (routeData?.route as unknown as CategoryInterface)?.uid,
            }),
            async () =>
              await magentoClientNoCache.request<CategoriesQuery>(useCategoriesQuery.document, {
                uid: (routeData?.route as unknown as CategoryInterface)?.uid,
              }),
          )

          if (!!cioCollectionId || !!cioGroupId) {
            break
          }

          const filter = transformFilterToMagentoFilterObject(
            (routeData?.route as unknown as CategoryInterface)?.uid,
            parseFilterString(params?.filter as string),
          )
          const hasSpecialOfferCategory = parseSpecialOfferCategory().includes(slug)
          await queryClient.prefetchInfiniteQuery(
            ['products', filter, PAGE_SIZE, parseSortString('', hasSpecialOfferCategory)],
            async () =>
              await magentoClientNoCache.request<ProductsQuery>(useProductsQuery.document, {
                filter,
                pageSize: PAGE_SIZE,
                currentPage: 1,
                sort: parseSortString('', hasSpecialOfferCategory),
              }),
          )
          break
        default:
          // Check for the possible CMS pages redirects
          const redirect = redirects?.cms?.find(
            (v) => v?.store === getStoreCode({ locale: finalLocale }) && v?.target === slug,
          )
          if (redirect) {
            return {
              redirect: {
                statusCode: redirect.status == 301 ? 301 : 302,
                destination:
                  !redirect.destination.startsWith('http://') &&
                  !redirect.destination.startsWith('https://')
                    ? storeDomain + redirect.destination
                    : redirect.destination,
              },
            }
          }

          if (!prefetchCurrentPage && slug !== 'home') return { notFound: true }
          await prefetchLocalizedSlugs(slug)
          break
      }
    } catch (err) {
      console.log(err)
    }
    /*** ----- END: prefetching dynamic contents ----- ***/
  }

  queryClient.setQueryData(PREVIEW_MODE_KEY, preview)

  if (process.env.NEXT_PUBLIC_CONTENTFUL_ENV === 'stage') {
    return {
      props: {
        ...(await serverSideTranslations(finalLocale, ['common', 'geopopup'])),
        // yes this is weird, but
        // https://github.com/vercel/next.js/discussions/11209#discussioncomment-35915
        dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
        structuredData: fetchedStructuredData,
        preview,
        cioCollectionId,
        cioGroupId,
        cioShowVariationsStrategy,
      },
      revalidate: 10,
    }
  } else {
    return {
      props: {
        ...(await serverSideTranslations(finalLocale, ['common', 'geopopup'])),
        // yes this is weird, but
        // https://github.com/vercel/next.js/discussions/11209#discussioncomment-35915
        dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
        structuredData: fetchedStructuredData,
        preview,
        cioCollectionId,
        cioGroupId,
        cioShowVariationsStrategy,
      },
      revalidate: 60 * 60 * 24, // 1 Day
    }
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  return { paths: [{ params: { slug: ['/'] } }], fallback: 'blocking' }
}

export default Page
