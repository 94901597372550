import { ReactNode } from 'react'
import NextLink from 'next/link'
import clsx from 'clsx'

import { isExternal } from '~/lib/externalLink'
import { makeAbsolute } from '~/lib/makeAbsolute'

export type Props = {
  children?: ReactNode
  href?: string
  className?: string
  title?: string
  textType?: 'Big' | 'Medium' | 'Small'
  isTextMediumBold?: boolean
  disabled?: boolean
  isUnderlinedOnHover?: boolean
  id?: string
  prefetch?: boolean
  target?: string
  style?: React.CSSProperties
  onClick?: () => void
}

export const Link = ({
  children,
  href,
  disabled,
  className,
  title,
  isTextMediumBold,
  textType,
  isUnderlinedOnHover,
  id,
  prefetch,
  target,
  onClick,
  style,
}: Props) => {
  const getStyleClasses = (textType?: 'Big' | 'Medium' | 'Small') => {
    const baseStyles = clsx(
      className && className,
      isTextMediumBold && 'font-medium',
      textType && 'underline underline-offset-8',
      disabled ? 'pointer-events-none text-grey-medium' : 'decoration-primary-black',
      isUnderlinedOnHover && 'hover:underline underline-offset-8',
    )
    if (textType) {
      switch (textType) {
        case 'Big':
          return clsx(baseStyles, 'text-15-15-sg')
        case 'Medium':
          return clsx(baseStyles, 'text-12-12-sg')
        case 'Small':
          return clsx(baseStyles, 'text-10-12-sg')
        default:
          return ''
      }
    }
    return baseStyles
  }

  if (href && !isExternal(href)) {
    return (
      <NextLink
        id={id}
        href={makeAbsolute(href)}
        className={getStyleClasses(textType)}
        title={title}
        prefetch={prefetch}
        target={target}
        onClick={onClick}
        style={style}
      >
        {children}
      </NextLink>
    )
  }

  return (
    <a
      id={id}
      tabIndex={0}
      href={href}
      className={getStyleClasses(textType)}
      title={title}
      target={target}
      onClick={onClick}
      style={style}
    >
      {children}
    </a>
  )
}
